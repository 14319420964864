<template>
  <div class="party-building">
    <img src="@/assets/mbbanner.png" alt="" class="banner" />
    <div class="tabs">
      <div
        :class="tabIndex === index ? 'tabs-active tabs-item' : 'tabs-item'"
        v-for="(item, index) in tabList"
        :key="index"
        @click="tabClick(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="list">
      <div
        class="list-item"
        v-for="(item, index) in list"
        :key="index"
        @click="handleClick(item)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 0,
      tabList: ["党纪教育", "主题教育", "二十大"],
      list: [],
      list1: [
        {
          title: "党纪学习教育——中央部署",
          url: "https://www.12371.cn/djxx/zybs/",
          type: 0,
        },
        {
          title: "和行公司各基层党组织召开党纪学习教育启动部署会",
          url: "",
          type: 1,
        },
        {
          title: "和行第一党支部党纪学习教育读书班开班",
          url: "",
          type: 2,
        },
        {
          title: "和瑞公司党支部党纪学习教育读书班开班",
          url: "",
          type: 3,
        },
        {
          title: "和行第一党支部开展党纪学习教育专题党课",
          url: "",
          type: 4,
        },
        {
          title: "和瑞公司党支部开展党纪学习教育专题党课",
          url: "",
          type: 5,
        },
      ],
      list2: [
        {
          title: "学习贯彻习近平新时代中国特色社会主义思想",
          url: "https://www.12371.cn/special/xxzd/hxnr/",
        },
        {
          title: "安徽先锋网-学习贯彻习近平新时代中国特色社会主义主题教育",
          url: "https://www.ahxf.gov.cn/Zt/shzysx/Index",
        },
        {
          title: "习近平在安徽考察时强调",
          url: "https://tv.cctv.com/2020/08/21/VIDEEqjgK89ztrAfa8DjjUo8200821.shtml",
        },
        {
          title:
            "习近平：健全全面从严治党体系 推动新时代党的建设新的伟大工程向纵深发展",
          url: "https://www.ahxf.gov.cn/Zt/shzysx/Content?Id=1144442&ClassId=6789",
        },
        {
          title: "学习汇编 | 习近平这样阐述“以学铸魂”",
          url: "https://www.ahxf.gov.cn/Zt/shzysx/Content?Id=1144031&ClassId=6799",
        },
        {
          title:
            "江汽集团公司党委组织领导干部赴省党风廉政教育馆开展主题警示教育",
          url: "https://info.jac.com.cn/info/ztjy/20230517/2675.html",
        },
        {
          title:
            "江汽集团公司党委召开学习贯彻习近平新时代中国特色社会主义思想主题教育动员部署会",
          url: "https://info.jac.com.cn/info/ztjy/20230421/2644.html",
        },
      ],
      list3: [
        {
          title: "习近平在学习贯彻二十大精神研讨班上发表重要讲话",
          url: "https://www.12371.cn/2023/02/07/ARTI1675772723487442.shtml",
        },
        {
          title: "和行党总支书记有关二十大的党课开展",
          url: "",
          type: 6,
        },
        {
          title: "江汽集团完成学习贯彻党的二十大精神集中轮训工作",
          url: "https://info.jac.com.cn/info/ershida/20230403/2640.html",
        },
        {
          title:
            "学习党发展全过程人民民主，推进法治中国建设——四论学习贯彻党的二十大精神的二十大精神",
          url: "http://www.news.cn/politics/2022-10/30/c_1129088765.htm",
        },
        {
          title: "肩负起新时代新征程党的使命任务——一论学习贯彻党的二十大精神",
          url: "http://www.news.cn/politics/cpc20/2022-10/25/c_1129079952.htm",
        },
        {
          title: "牢牢把握团结奋斗的时代要求",
          url: "http://www.news.cn/comments/2022-10/22/c_1129075323.htm",
        },
      ],
    };
  },
  created() {
    this.list = this.list1;
  },
  methods: {
    tabClick(index) {
      this.tabIndex = index;
      this.list =
        index === 0 ? this.list1 : index === 1 ? this.list2 : this.list3;
    },
    handleClick(e) {
      if (e.url === "") {
        if (e.type === 1) {
          this.$router.push({
            name: "MBPartydisciplineStudy",
          });
        } else if (e.type === 2) {
          this.$router.push({
            name: "MBPartyOne",
          });
        } else if (e.type === 3) {
          this.$router.push({
            name: "MBPartyTwo",
          });
        } else if (e.type === 4) {
          this.$router.push({
            name: "MBPartyThree",
          });
        } else if (e.type === 5) {
          this.$router.push({
            name: "MBPartyFour",
          });
        } else if (e.type === 6) {
          this.$router.push({
            name: "MBStudy",
          });
        }
      } else {
        window.open(e.url);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.party-building {
  .banner {
    width: 100%;
  }

  .tabs {
    display: flex;
    align-items: center;
    padding: 0.54rem 0.48rem 0.14rem;

    &-item {
      font-size: 0.32rem;
      color: #8d8b99;
      margin-left: 1rem;
    }

    &-item:first-child {
      margin-left: 0;
    }

    &-item::after {
      content: "";
      display: block;
      width: 0;
      height: 0.04rem;
      background: #e63c3c;
      position: absolute;
      top: 0.45rem;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease;
    }

    &-active {
      color: #2d2d39;
      position: relative;
    }

    &-active::after {
      width: 50%;
    }
  }

  .list {
    padding: 0 0.48rem;

    &-item {
      color: #626272;
      font-size: 0.28rem;
      border-bottom: solid 0.01rem #e3e3e3;
      padding: 0.33rem 0;
    }

    &-item:last-child {
      border-bottom: 0;
    }
  }
}
</style>